import React, { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { H1 } from '../atoms/Headers';

const Container = styled.div`
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  font-style: italic;
`;

interface GlobalHeaderProps {
  signedIn: boolean;
}

const GlobalHeader: FC<GlobalHeaderProps> = () => {
  const navigate = useNavigate();

  const Name = styled(H1)`
    letter-spacing: 1rem;
    padding-top: 2rem;
    text-align: center;
    cursor: pointer;
  `;

  const handleNameClick = () => navigate('/');

  return (
    <Container>
      <Name onClick={handleNameClick}>Joyce Eccleston</Name>
    </Container>
  );
};

export default GlobalHeader;
