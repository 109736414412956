import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '../components/atoms/Button';
import { useNavigate } from 'react-router';

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  align-items: center;
`;

const BioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Bio = styled.p`
  width: 75%;
  margin: 15px 0;
  text-align: center;
`;

const ProjectsContainer = styled.div``;

const ContactButton = styled(Button)`
  width: 15rem;
`;

const bioMessage = `I earned my Bachelor’s degree in Fine Art with a focus on Painting, and my work is rooted in abstract expressionism. I love to paint the people and places that mean the most to me, finding beauty in even the smallest, everyday moments. My art also explores my experiences as a woman, how I’m seen by society, and the value of the feminine form. Alongside creating art, I’m a teacher who truly loves what I do, and my students constantly inspire and energize me.`;

const LandingPage: FC = () => {
  const navigate = useNavigate();

  const handleContactClick = () => navigate('/contact');

  return (
    <Container>
      <ContactButton onClick={handleContactClick}>Contact Me</ContactButton>
      <BioContainer>
        <Bio>{bioMessage}</Bio>
      </BioContainer>
      <ProjectsContainer></ProjectsContainer>
    </Container>
  );
};

export default LandingPage;
