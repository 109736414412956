import React, { FC } from 'react';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formOptions, useForm } from '@tanstack/react-form';

import { Button } from '../components/atoms/Button';
import { Dayjs } from 'dayjs';

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const inputSx = {
  width: '75%',
  margin: '15px 0',
  backgroundColor: 'white',

  '& .MuiOutlinedInput-input': {
    backgroundColor: 'white',
    borderRadius: '5px',
  },

  '& .MuiIconButton-edgeEnd': {
    marginRight: '0',
  },

  '& .MuiInputBase-multiline': {
    width: '100%',
  },
};

interface ContactPageProps {}

interface ContactOpts {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  requestedCompletionDate: Dayjs | null;
  message: string;
}

const formOpts = formOptions<ContactOpts>({
  defaultValues: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    requestedCompletionDate: null,
    message: '',
  },
});

export const ContactPage: FC<ContactPageProps> = () => {
  const [customDate, setCustomDate] = React.useState<Dayjs | null>(null);

  const form = useForm({
    ...formOpts,
    onSubmit: async ({ value }) => {
      // Do something with form data
      console.log(value);
    },
  });

  return (
    <Container>
      <form.Field
        name="firstName"
        children={(field) => (
          <TextField
            label="First Name"
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            sx={inputSx}
          />
        )}
      />
      <form.Field
        name="lastName"
        children={(field) => (
          <TextField
            label="Last Name"
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            sx={inputSx}
          />
        )}
      />
      <form.Field
        name="email"
        children={(field) => (
          <TextField
            label="Email"
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            sx={inputSx}
          />
        )}
      />
      <form.Field
        name="phone"
        children={(field) => (
          <TextField
            label="Phone"
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            sx={inputSx}
          />
        )}
      />
      <form.Field
        name="requestedCompletionDate"
        children={(field) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Requested Completion Date"
              value={field.state.value}
              onChange={(date) => field.handleChange(date)}
              sx={inputSx}
            />
          </LocalizationProvider>
        )}
      />
      <form.Field
        name="message"
        children={(field) => (
          <TextField
            label="Message"
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            sx={inputSx}
            multiline
            rows={5}
          />
        )}
      />
      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
        children={([canSubmit, isSubmitting]) => (
          <Button type="submit" disabled={!canSubmit} style={{ width: '50%' }}>
            {isSubmitting ? '...' : 'Submit'}
          </Button>
        )}
      />
    </Container>
  );
};
