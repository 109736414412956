import React from 'react';
import styled from 'styled-components';

export const Button = styled.button<
  React.ComponentProps<'button'> & React.HTMLAttributes<HTMLButtonElement>
>`
  transition: all 0.25s ease-in-out;
  padding: 8px 35px;
  border-radius: 5px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  border: 2px solid black;
  background-color: #9fffe2;

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      `
      transform: scale(1.02, 1.01);
      box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.4);
      scale: 1.02;
      `}
  }
`;
